import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TriggerModel } from '../_models/TriggerModel';

@Injectable({
  providedIn: 'root'
})
export class TriggerApiService {
  constructor(private http: HttpClient) {}

  async getAllTriggers(): Promise<Observable<any>> {
    // As of 10/9/24, the GET triggers endpoint includes a per_page param default value of 100:
    // https://iot-triggers-api.dev.zonarsystems.net/api/v1beta1#/triggers/search_triggers_api_v1beta1_triggers_get
    // Per JIRA ticket GTI-1820, we needed more than 100 available in the displayed Triggers table.
    // If ever there are more than 1000 triggers present in account, additional work will need to be done to
    // ensure these triggers are available in this frontend (since the maximum per_page value is currently 1000)
    return await this.http
      .get(`${environment.triggerapi}/triggers`, { params: { per_page: 1000 } })
      .pipe(
        catchError((err) => {
          console.log('Error caught in getting all Trigger API service');
          console.error(err);
          //Handle the error here
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  getTriggerById(triggerId: string): Observable<TriggerModel> {
    let url = `${environment.triggerapi}/triggers/${triggerId}`;
    return this.http.get<TriggerModel>(url);
  }

  createTrigger(body: any): Observable<any> {
    let url = `${environment.triggerapi}/triggers`;
    return this.http.post(url, body);
  }

  /*   updateTrigger(body: any, triggerID: string): Observable<any> {
    let url = `${environment.triggerapi}/triggers/${triggerID}`;
    return this.http.patch(url, body);

  } */

  async updateTrigger(body: any, triggerID: string): Promise<Observable<any>> {
    return await this.http
      .patch(`${environment.triggerapi}/triggers/${triggerID}`, body)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => {
          console.log('error caught in update trigger() service');
          console.error(err);

          return throwError(err); //Rethrow it back to component
        })
      );
  }
}
